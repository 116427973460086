// Todo field type plug-in code
;(function ($, DataTable) {

  function _togglePassword( input, toggle ) {
    if (input.attr('type') === 'password') {          
      input.attr('type', 'text')
    } else {
      input.attr('type', 'password')
    }
    $(toggle).find('i').toggleClass('fa-eye fa-eye-slash')
  }

  if (!DataTable.ext.editorFields) {
    DataTable.ext.editorFields = {}
  }

  var Editor = DataTable.Editor
  var _fieldTypes = DataTable.ext.editorFields

  _fieldTypes.passwordToggle = $.extend(true, {}, _fieldTypes.password, {
    create: function (conf) {
      conf._input = $('<input/>').attr($.extend({
        id: Editor.safeId(conf.id),
        type: 'password'
      }, conf.attr || {})).addClass('form-control')      
      conf._toggleBtn = $('<button/>').addClass('btn btn-default').html('<i class="fa fa-eye"></i>').attr('type', 'button')
      conf._toggleBtn.click(function() {
        _togglePassword(conf._input, this)
      })
      var inputGroupBtn = $('<span/>').addClass('input-group-btn').append(conf._toggleBtn)
      conf._wrapper = $('<div/>').addClass('input-group').append(conf._input).append(inputGroupBtn)

      return conf._wrapper;
    },

    toggle: function ( conf ) {
      _togglePassword(conf._input, conf._toggleBtn)
    },
    toggleVisible: function ( conf ) {
      if ((conf._input.attr('type') === 'password')) {
        _togglePassword(conf._input, conf._toggleBtn)
      }
    },

    toggleHide: function ( conf ) {
      if ((conf._input.attr('type') === 'text')) {
        _togglePassword(conf._input, conf._toggleBtn)
      }
    },

    visible: function ( conf ) {
      return (conf._input.attr('type') === 'text')
    },
  
    enable: function ( conf ) {
      conf._input.prop( 'disabled', false );
      conf._toggleBtn.prop( 'disabled', false );
    },
  
    disable: function ( conf ) {
      conf._input.prop( 'disabled', true );
      conf._toggle.prop( 'disabled', true );
    }
  })
})(window.jQuery, window.jQuery.fn.dataTable);