;(function (factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD
    define(['jquery', 'datatables', 'datatables-editor'], factory)
  }
  else if (typeof exports === 'object') {
    // Node / CommonJS
    module.exports = function ($, dt) {
      if (! $) { $ = require('jquery'); }
      factory($, dt || $.fn.dataTable || require('datatables'))
    }
  }
  else if (jQuery) {
    // Browser standard
    factory(jQuery, jQuery.fn.dataTable)
  }
}(function ($, DataTable) {
  'use strict'

  if (! DataTable.ext.editorFields) {
    DataTable.ext.editorFields = {}
  }

  var _fieldTypes = DataTable.ext.editorFields

  _fieldTypes.divider = {
    create: function (field) {
      return '<div role="separator" class="divider"><div/>'
    },
    get: function (field) { return ''; },
    set: function (field, val) {}
  }
}));
