/**
 *  Text input field plug-in with an "action" button
 *  use: type: 'textAction'
 *  Configuration attributes:
 *     actionTitle: title attribute for the button element
 *     onAction: callback for the action button
 * 
 */

;(function ($, DataTable) {

  if (!DataTable.ext.editorFields) {
    DataTable.ext.editorFields = {}
  }

  const Editor = DataTable.Editor
  const _fieldTypes = DataTable.ext.editorFields

  _fieldTypes.textAction = $.extend(true, {}, _fieldTypes.text, {
    create: function (conf, object) {
      const editor = this
      conf._input = $('<input/>').attr($.extend({
        id: Editor.safeId(conf.id),
        type: 'text-action'
      }, conf.attr || {})).addClass('form-control')   

      conf._actionBtn = $('<button/>').addClass('btn btn-default').html('<i class="fa fa-refresh"></i>').attr('type', 'button')
      if (conf.actionTitle) {
        conf._actionBtn.attr('title', conf.actionTitle)
      }

      conf._actionBtn.click(() => {
        if (conf.onAction) {
          conf.onAction(conf._input, editor)
        }
      })
      const inputGroupBtn = $('<span/>').addClass('input-group-btn').append(conf._actionBtn)
      conf._wrapper = $('<div/>').addClass('input-group').append(conf._input).append(inputGroupBtn)

      return conf._wrapper;
    },
  
    enable: function ( conf ) {
      conf._input.prop( 'disabled', false );
      conf._actionBtn.prop( 'disabled', false );
    },
  
    disable: function ( conf ) {
      conf._input.prop( 'disabled', true );
      conf._toggle.prop( 'disabled', true );
    }
  })
})(window.jQuery, window.jQuery.fn.dataTable);