// selectpicker field type plug-in code for datatables editors
;(function ($, DataTable) {
  // copied from datatables.editor.js
  function _triggerChange (input) {
    setTimeout(function () {
      input.trigger('change', {editor: true})
			input.selectpicker('render');
    }, 0)
  }

  if (!DataTable.ext.editorFields) {
    DataTable.ext.editorFields = {}
  }

  const Editor = DataTable.Editor
  const fieldTypes = DataTable.ext.editorFields

  fieldTypes.selectPicker = {
    // Locally "private" function that can be reused for the create and update methods
    _addOptions: function (conf, opts) {
      let elOpts = conf._input[0].options
      let countOffset = 0

      elOpts.length = 0

      if (conf.placeholder !== undefined) {
        countOffset += 1
        const placeholderValue = conf.placeholderValue !== undefined ? conf.placeholderValue : ''
        elOpts[0] = new Option(conf.placeholder, placeholderValue)
        elOpts[0]._editor_val = placeholderValue

        const disabled = conf.placeholderDisabled !== undefined ?
          conf.placeholderDisabled :
          true

        elOpts[0].hidden = disabled; // can't be hidden if not disabled!
        elOpts[0].disabled = disabled
      }

      if (opts) {
        Editor.pairs(opts, conf.optionsPair, function (val, label, i) {
          const optElement = new Option(label, val)
          elOpts[ i + countOffset ] = optElement
          optElement._editor_val = val
          if (conf.renderLabel) {
            optElement.innerHTML = conf.renderLabel(label, val ,i, opts[i])
            optElement.setAttribute('data-content', optElement.innerHTML)
          }
        })
      }
    },

    create: function (conf) {
      conf._input = $('<select/>')
        .attr($.extend({
          id: Editor.safeId(conf.id),
          multiple: conf.multiple === true,
          'class': 'selectpicker show-tick show-menu-arrow',
          'data-live-search': 'true',
          'data-live-search-normalize': 'true',
          'data-live-search-style': 'containsAll',
          'data-width': '100%',
          'data-size': '15'  // limit display to 15 elements
        }, conf.attr || {}))
        .on('change.dte', function (e, d) {
          // On change, get the user selected value and store it as the
          // last set, so `update` can reflect it. This way `_lastSet`
          // always gives the intended value, be it set via the API or by
          // the end user.
          if (! d || ! d.editor) {
            conf._lastSet = fieldTypes.selectPicker.get(conf)
          }
        })

      fieldTypes.selectPicker._addOptions(conf, conf.options || conf.ipOpts)

      return conf._input[0]
    },

    update: function (conf, options) {
      fieldTypes.selectPicker._addOptions(conf, options)

      // Attempt to set the last selected value (set by the API or the end
      // user, they get equal priority)
      let lastSet = conf._lastSet

      if (lastSet !== undefined) {
        fieldTypes.selectPicker.set(conf, lastSet, true)
      }

      _triggerChange(conf._input)
      conf._input.selectpicker('refresh')
    },

    get: function (conf) {
      const selectedOptions = Array.from(conf._input[0].querySelectorAll('option:checked'))
      const values = selectedOptions.map(option => option._editor_val)

      if (conf.multiple) {
        return conf.separator ? values.join(conf.separator) : values
      }
      return values.length ? values[0] : null
    },

    set: function (conf, val, localUpdate) {
      if (! localUpdate) {
        conf._lastSet = val
      }

      // Can't just use `$().val()` because it won't work with strong types
      if (conf.multiple && conf.separator && ! $.isArray(val)) {
        val = val.split(conf.separator)
      }
      else if (! $.isArray(val)) {
        val = [ val ]
      }

      let i, len = val.length, found, allFound = false
      const options = Array.from(conf._input[0].querySelectorAll('option')) //conf._input.find('option')

      options.forEach(option => {
        found = false

        for (i = 0; i < len; i++) {
          // Weak typing
          if (option._editor_val == val[i]) {
            found = true
            allFound = true
            break
          }
        }

        option.selected = found
      })

      // If there is a placeholder, we might need to select it if nothing else
      // was selected. It doesn't make sense to select when multi is enabled
      if (conf.placeholder && ! allFound && ! conf.multiple && options.length) {
        options[0].selected = true
      }

      // Update will call change itself, otherwise multiple might be called
      if (! localUpdate) {
        _triggerChange(conf._input)
      }

      return allFound
    },

    enable: function (conf) {
      conf._input.prop('disabled', false)
      conf._input.selectpicker('refresh')
    },

    disable: function (conf) {
      conf._input.prop('disabled', true)
      conf._input.selectpicker('refresh')
    },

    destroy: function (conf) {
      conf._input.off('change.dte')
    }
  }
})(window.jQuery, window.jQuery.fn.dataTable);
