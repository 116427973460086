// Todo field type plug-in code
;(function ($, DataTable) {

  if (!DataTable.ext.editorFields) {
    DataTable.ext.editorFields = {}
  }

  var Editor = DataTable.Editor
  var _fieldTypes = DataTable.ext.editorFields
  var wrapperStyle = {'margin-top': '0.4em', 'margin-bottom': '0.6em', 'float': 'left', 'width': '100%'}

  _fieldTypes.numberLabel = $.extend(true, {}, _fieldTypes.text, {
    create: function (conf) {
      conf._input = $('<input/>').attr($.extend({
        id: Editor.safeId(conf.id),
        type: 'number'
      }, conf.attr || {})).addClass(['col-xs-3 col-sm-3 col-md-3 col-lg-3', 'form-control', 'form-control-width-25'])
      conf._innerLabel = $('<span/>').text(conf.innerLabel).addClass(['col-xs-6 col-sm-6 col-md-6 col-lg-6', 'control-label', 'control-label-left'])
      conf._wrapper = $('<div/>').css(wrapperStyle).append(conf._input).append(conf._innerLabel)

      return conf._wrapper;
    },
  
    enable: function ( conf ) {
      conf._input.prop( 'disabled', false );
      conf._innerLabel.prop( 'disabled', false );
    },
  
    disable: function ( conf ) {
      conf._input.prop( 'disabled', true );
      conf._innerLabel.prop( 'disabled', true );
    }
  })
})(window.jQuery, window.jQuery.fn.dataTable);