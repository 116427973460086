// Todo field type plug-in code
;(function ($, DataTable) {
  if (!DataTable.ext.editorFields) {
    DataTable.ext.editorFields = {}
  }

  var Editor = DataTable.Editor
  var _fieldTypes = DataTable.ext.editorFields

  _fieldTypes.color = {
    create: function (conf) {
      var that = this

      conf._enabled = true

      // Create the elements to use for the input
      conf._input = $(
        '<div id="' + Editor.safeId(conf.id) + '">' +
        '   <input type="color" class="color-picker">' +
        '</div>')

      // Use the fact that we are called in the Editor instance's scope to call
      // the API method for setting the value when needed
      $('input.color-picker', conf._input).change(function () {
        if (conf._enabled) {
          that.set(conf.name, $(this).val())
        }
        return false
      })
      return conf._input
    },

    get: function (conf) {
      return $('input.color-picker', conf._input).val()
    },

    set: function (conf, val) {
      $('input.color-picker', conf._input).val(val)
    },

    enable: function (conf) {
      conf._enabled = true
      $(conf._input).removeClass('disabled')
    },

    disable: function (conf) {
      conf._enabled = false
      $(conf._input).addClass('disabled')
    }
  }
})(window.jQuery, window.jQuery.fn.dataTable);
