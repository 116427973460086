// Todo field type plug-in code
;(function ($, DataTable) {

  function _triggerChange (input) {
    setTimeout(function () {
      input.trigger('change', {editor: true, editorSet: true}) // editorSet legacy
    }, 0)
  }

  if (!DataTable.ext.editorFields) {
    DataTable.ext.editorFields = {}
  }

  var Editor = DataTable.Editor
  var _fieldTypes = DataTable.ext.editorFields

  _fieldTypes.checkboxFlex = {
    // Locally "private" function that can be reused for the create and update methods
    _addOptions: function (conf, opts) {
      var val, label
      var elOpts = conf._input[0].options
      var jqInput = conf._input.empty()

      if (opts) {
        Editor.pairs(opts, conf.optionsPair, function (val, label, i) {
          jqInput.append(
            '<div style="display:flex;">' +
            '<input id="' + Editor.safeId(conf.id) + '_' + i + '" type="checkbox" />' +
            '<label for="' + Editor.safeId(conf.id) + '_' + i + '">' + label + '</label>' +
            '</div>'
          )
          $('input:last', jqInput).attr('value', val)[0]._editor_val = val
        })
      }
    },

    create: function (conf) {
      conf._input = $('<div />')
      _fieldTypes.checkboxFlex._addOptions(conf, conf.options || conf.ipOpts)

      return conf._input[0]
    },

    get: function (conf) {
      var out = []
      var inputSelector = conf.unselectedValue !== undefined ? 'input' : 'input:checked'
      conf._input.find(inputSelector).each(function (id, obj) {
        if (!obj.checked) {
          return out.push(conf.unselectedValue)
        }
        out.push(this._editor_val)
      })
      return conf.separator == undefined ?
        out :
        out.length === 1 ?
          out[0] :
          out.join(conf.separator)
    },

    set: function (conf, val) {
      var jqInputs = conf._input.find('input')
      if (! $.isArray(val) && typeof val === 'string') {
        val = val.split(conf.separator || '|')
      }
      else if (! $.isArray(val)) {
        val = [ val ]
      }

      var i, len = val.length, found

      jqInputs.each(function () {
        found = false

        for (i = 0; i < len; i++) {
          if (this._editor_val == val[i]) {
            found = true
            break
          }
        }

        this.checked = found
      })

      _triggerChange(jqInputs)
    },

    enable: function (conf) {
      conf._input.find('input').prop('disabled', false)
    },

    disable: function (conf) {
      conf._input.find('input').prop('disabled', true)
    },

    update: function (conf, options) {
      // Get the current value
      var checkboxFlex = _fieldTypes.checkboxFlex
      var currVal = checkboxFlex.get(conf)

      checkboxFlex._addOptions(conf, options)
      checkboxFlex.set(conf, currVal)
    }
  }
})(window.jQuery, window.jQuery.fn.dataTable);
